import { debug, error, info } from '../controller/util/Logger';
/**
 * @typedef eventDetail
 * @property {number} state
 * @property {string} stateDetail
 * @property {string} [previousState]
 * @property {string} [error]
 * @property {string} [videoToken]
 * @property {any} userData
 */
/**
 * @typedef {CustomEvent} ssoEvent
 * @property {eventDetail} detail
 */
/**
 * @callback videoTokenCallback
 * @param {string} videoToken
 */
window.VRT = window.VRT || {};

const { VRT } = window;

/**
 * @type {{updateIdentityToken, unloadPlayers}}
 */
window.VRTMediaPlayer = window.VRTMediaPlayer || { loading: true };
if (VRT.SSOEventHandler) {
    error('VRT.SSOEventHandler has already been taken. Exiting...');
} else {
    VRT.SSOEventHandler = (function _SSOEventHandler() {
        let videoToken = null;
        let bootstrapped = false;
        let lastFunction = null;
        let stateChangedEventReceived = false;
        let videoTokenCallback = [];
        let state = null;

        function updateIdentityToken() {
            if (bootstrapped) {
                try {
                    debug(`Calling player updateIdentityToken with new video token: ${JSON.stringify(videoToken)}`);
                    window.VRTMediaPlayer.updateIdentityToken(videoToken);
                } catch (e) {
                    info(`couldn't update Identity Token ${JSON.stringify(e)}`);
                }
            } else {
                lastFunction = updateIdentityToken;
            }
        }

        function logout() {
            if (bootstrapped) {
                try {
                    window.VRTMediaPlayer.unloadPlayers();
                } catch (e) {
                    info('couldn\'t unload players');
                }
            } else {
                lastFunction = logout;
            }
        }

        /**
         * @param {ssoEvent} event
         * @returns {boolean}
         */
        function hasNewVideoToken(event) {
            return event && event.detail && event.detail.videoToken && event.detail.videoToken !== videoToken;
        }

        /**
         * @param {ssoEvent} event
         * @returns {boolean}
         */
        function isLoggedOut(event) {
            return event && event.detail && event.detail.state <= -1; // logged out or disabled
        }

        function callVideoTokenCallbacks() {
            const vt = videoToken !== null;
            videoTokenCallback = videoTokenCallback.filter((cb) => { if (!cb.onlyLegal || vt) { cb.callback(videoToken); return false; } return true; });
        }

        /**
         * @param {ssoEvent} event
         */
        function listenForVideoToken(event) {
            debug(`Received an event: ${JSON.stringify(event)}`);
            stateChangedEventReceived = true;
            if (isLoggedOut(event)) {
                videoToken = null;
                if (state > 0) {
                    logout();
                }
            } else if (hasNewVideoToken(event)) {
                ({ videoToken } = event.detail);
                updateIdentityToken();
            }
            callVideoTokenCallbacks();
            ({ state } = event.detail);
        }

        function listenForVideoplayerBootstrapped() {
            bootstrapped = true;
            if (lastFunction) lastFunction();
        }

        /**
         * callback will be called after the first sso-stateChanged event is received
         * @param {videoTokenCallback} callback
         * @param {boolean} [onlyLegal]
         */
        function getVideoToken(callback, onlyLegal = false) {
            videoTokenCallback.push({ callback, onlyLegal });
            if (stateChangedEventReceived) {
                setTimeout(callVideoTokenCallbacks, 0);
            }
        }

        /**
         * @constructor
         */
        function SSOEventHandler() {
            if (window.VRT && window.VRT.SSOController) {
                window.VRT.SSOController.addEventListener('SSO-stateChanged', listenForVideoToken);
            } else {
                window.addEventListener('ssoControllerBootstrapped', () => window.VRT.SSOController.addEventListener('SSO-stateChanged', listenForVideoToken));
            }
            if (window.VRTMediaPlayer.loading) {
                window.addEventListener('vrtPlayerBootstrapped', listenForVideoplayerBootstrapped);
            } else {
                listenForVideoplayerBootstrapped();
            }
        }

        SSOEventHandler.getVideoToken = getVideoToken;
        window.dispatchEvent(new CustomEvent('ssoEventHandlerBootstrapped', { bootstrapped: true }));

        return SSOEventHandler;
    }());

    // eslint-disable-next-line no-new
    new VRT.SSOEventHandler();
}
