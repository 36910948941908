import { error, info } from '../controller/util/Logger';
import LoginState from '../controller/util/LoginState';

window.VRT = window.VRT || {};

const { VRT } = window;
/**
 * @typedef displayElement
 * @property {string} title
 * @property {string} text
 * @property {object} button
 * @property {string} button.label
 * @property {function} button.click
 */

/**
 *
 * @type {Object.<string, displayElement>}
 */
const displayList = {
    noAuth: {
        title: 'Aanmelden',
        text: `Om deze video te bekijken moet je aangemeld zijn.<br/>
        Gelieve aan te melden met je VRT-profiel.<br/>
        Meer info vind je op de <a href="https://www.vrt.be/nl/info/vrt-profiel1/">VRT-website</a>`,
        button: {
            click: () => VRT.SSOController.manualLogin(),
            label: 'Aanmelden',
        },
    },
    ageRestriction: {
        title: 'Leeftijdsbeperking',
        text: `Je voldoet niet aan de vereiste leeftijd om deze inhoud te bekijken.<br/>
        Meer info vind je op de <a href="https://www.vrt.be/nl/over-de-vrt/beleid/beroepsethiek/leeftijdslabels/">VRT-website</a>`,
        button: {},
        cancel: 'Jammer',
    },
    geoblocked: {
        title: 'Geen rechten',
        text: 'Voor sommige programma\'s heeft VRT enkel de rechten voor de Belgische markt.<br/>',
        button: {},
        cancel: 'Jammer',
    },
    alternateGeoblocked: { // There's a chance this might be needed in the future
        // it seems at this moment that geoblocked videos give error 104 even when the user is in Belgium but just not logged in.
        // I added this for Sporza, but right now they don't want to use it yet.
        title: 'Aanmelden',
        text: `Deze video kan je enkel zien als je aangemeld bent in België.<br/>
        Gelieve aan te melden met je VRT-profiel.<br/>
        Meer info vind je op de <a href="https://www.vrt.be/nl/info/vrt-profiel1/">VRT-website</a>`,
        button: {
            click: () => VRT.SSOController.manualLogin(),
            label: 'Aanmelden',
        },
    },
    checkRoaming: {
        title: 'Even geduld',
        text: 'We controleren even of je in België woont aan de hand van je gsm-nummerverificatie.',
        button: {},
    },
};

/**
 *
 * @returns boolean
 */
const isLoggedIn = () => VRT.SSOController.getState() === LoginState.loggedIn;
/**
 *
 * @param {string} scope
 */
const needExtraScope = (scope) => {
    if (isLoggedIn()) {
        VRT.SSOController.addScopePrompting(scope);
    } else {
        VRT.SSOController.manualLogin(scope);
    }
};

/**
 *
 * @type {Object.<string, function>}
 */
const expatDisplayList = {
    CONTENT_AVAILABLE_ONLY_FOR_BE_RESIDENTS: () => {
        if (!isLoggedIn()) {
            return {
                title: 'Meld je aan om deze video te bekijken.',
                text: 'Je kan in het buitenland video\'s bekijken indien je bent aangemeld en je Belgische woonplaats of identiteit bevestigd hebt.',
                button: {
                    click: () => needExtraScope('portability'),
                    label: 'Bevestigen',
                },
            };
        }

        const { country } = (VRT.SSOController.getUserData() || { address: {} }).address;
        if (country !== 'BE') {
            return {
                title: 'Deze video is enkel beschikbaar voor inwoners van België.',
                text: 'Wij hebben geen rechten om deze video uit te zenden in het land waar jij woont.',
            };
        }

        return {
            title: 'Deze video is enkel beschikbaar voor inwoners van België.',
            text: 'Je kan in het buitenland video\'s bekijken indien je jouw Belgische woonplaats of identiteit bevestigd hebt.',
            button: {
                click: () => needExtraScope('portability'),
                label: 'Bevestigen',
            },
        };
    },
    // if we had a separate screen for nationality verification, this would have to be extended with not verified situations
    CONTENT_AVAILABLE_ONLY_FOR_BE_RESIDENTS_AND_EXPATS: () => {
        if (!isLoggedIn()) {
            return {
                title: 'Meld je aan om deze video te bekijken.',
                text: 'Je kan in het buitenland video\'s bekijken indien je bent aangemeld en je Belgische identiteit bevestigd hebt.',
                button: {
                    click: () => needExtraScope('portability'),
                    label: 'Bevestigen',
                },
            };
        }
        const { country } = (VRT.SSOController.getUserData() || { address: {} }).address;
        if (country === 'BE') {
            return {
                title: 'Deze video is enkel beschikbaar voor inwoners van België.',
                text: 'Indien je momenteel in het buitenland bent, kan je de video bekijken, nadat je je Belgische woonplaats of identiteit bevestigd hebt.',
                button: {
                    click: () => needExtraScope('portability'),
                    label: 'Bevestigen',
                },
            };
        }

        return {
            title: 'Deze video is enkel beschikbaar voor personen met een Belgische identiteit',
            text: 'Je kan in het buitenland video\'s bekijken indien je jouw Belgische identiteit bevestigd hebt.',
            button: {
                click: () => needExtraScope('portability'),
                label: 'Bevestigen',
            },
        };
    },
    CONTENT_IS_AGE_RESTRICTED: () => {
        if (isLoggedIn()) {
            return {
                title: 'Je bent nog wat jong',
                text: 'Sorry, je kan deze video nog niet bekijken. Maar je vindt vast en zeker iets anders dat even leuk is.',
            };
        }
        return {
            title: 'Ben je oud genoeg?',
            text: 'Meld je aan met je VRT-profiel. Zo kunnen we je leeftijd checken.',
            button: {
                click: () => VRT.SSOController.manualLogin(),
                label: 'Aanmelden',
            },
        };
    },
    CONTENT_REQUIRES_AUTHENTICATION: () => ({
        title: 'Aanmelden met je VRT-profiel',
        text: 'Zonder je aan te melden kan je alleen live kijken. Wil je iets anders bekijken? Meld je dan aan.',
        button: {
            click: () => VRT.SSOController.manualLogin(),
            label: 'Aanmelden',
        },
    }),
    CONTENT_AVAILABLE_ONLY_IN_BE: () => ({
        title: 'Deze video is enkel beschikbaar binnen België.',
        text: 'Wij hebben geen rechten om deze video uit te zenden in het land waar jij je nu bevindt.',
    }),
    CONTENT_UNAVAILABLE_VIA_PROXY: () => ({
        title: 'Sorry, niet beschikbaar',
        text: 'We hebben gedetecteerd dat je een proxy gebruikt. Jammer genoeg is dit niet toegelaten. Probeer op een andere manier te kijken.',
    }),
    CONTENT_CURRENTLY_UNAVAILABLE: () => ({
        title: 'Tijdelijk onbeschikbaar',
        text: 'Je kan deze video tijdelijk niet bekijken. Sorry voor het ongemak.',
    }),
    DEFAULT: () => ({
        title: 'Er liep iets mis',
        text: 'Er liep iets mis met de video. Neem contact op als het probleem aanhoudt',
    }),
};
const AudioplayerDisplayList = {
    CONTENT_REQUIRES_AUTHENTICATION: () => ({
        title: 'Ga verder met je VRT-profiel',
        // eslint-disable-next-line max-len
        text: 'Je kan momenteel enkel onze live programma\'s volgen. Wil je van dit fragment en ons hele aanbod genieten? Meld je dan aan met je VRT-profiel of maak eenvoudig een nieuw profiel aan. Veel plezier met ons aanbod!',
        button: {
            click: () => VRT.SSOController.manualLogin(),
            label: 'Aanmelden',
        },
    }),
    DEFAULT: () => ({
        title: 'Er liep iets mis',
        text: 'Er liep iets mis met de audio. Neem contact op als het probleem aanhoudt',
    }),
};

const overlayList = [];

/**
 * @param {Event} event
 */
const disableOverlay = (event) => {
    event.target.parentNode.parentNode.parentNode.classList.add('sso-overlay-hide');
};

/**
 * @param {HTMLElement} div
 */
const hidePreviousError = (div) => {
    const videoError = div.querySelector('.sso-overlay');
    if (videoError) {
        videoError.parentNode.removeChild(videoError);
    }
};

/**
 * @param {displayElement} display
 * @returns {HTMLDivElement}
 */
const createButtons = (display) => {
    const buttons = document.createElement('div');
    buttons.className = 'sso-overlay-buttons';

    if (display.button && display.button.label) {
        const button = document.createElement('button');
        button.className = 'sso-overlay-button';
        button.onclick = (event) => {
            disableOverlay(event);
            display.button.click();
        };
        button.textContent = display.button.label;
        buttons.appendChild(button);
    }

    return buttons;
};

const showOverlay = (div, display) => {
    hidePreviousError(div);
    // eslint-disable-next-line no-param-reassign
    div.classList.add('sso-overlay-parent');
    const overlay = document.createElement('div');
    overlay.className = 'sso-overlay';

    const wnd = document.createElement('div');
    wnd.className = 'sso-overlay-window';

    overlay.appendChild(wnd);

    const logo = document.createElement('div');
    logo.className = 'sso-overlay-logo';
    logo.innerHTML = `<svg viewBox="0 0 57 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M49.6159 3.69814C48.6677 2.84977 48.1188 1.57591 48.0137 0H42.2984C42.3388 1.13812 42.5331 2.26552 42.8762 3.35144C43.4262 5.11893 44.4381 6.70753 45.8074 7.9531C47.1459
    9.14312 48.7782 9.95379 50.5352 10.3012C51.3225 10.4622 52.1243 10.5423 52.9279 10.5402H54.7507V4.82491H52.9384C51.6042 4.82491 50.4616 4.44144 49.6264 3.69288"/>
    <path d="M38.5661 6.06463C31.6374 6.06463 22.7913 7.59064 25.0606 27.8227H31.2066C30.9702 25.1962 30.7969 23.0424 30.7785 20.9334C30.7785 14.9212 32.3544 11.428 38.4768 11.6775L39.7323
    11.7248V6.13029C39.3199 6.08302 38.6975 6.06726 38.5661 6.06463Z"/>
    <path d="M18.3603 6.13029C17.5015 9.28212 16.4114 12.7911 15.3267 16.0454C14.5387 18.4093 12.8919 22.8743 12.8919 22.8743H12.6293C11.7546 20.584 10.9903 18.4093 10.1945 16.0454C9.08609
    12.7938 7.94618 9.28474 7.09256 6.13029H0.5C1.65304 9.75226 2.9164 13.369 4.3137 17.0487C5.71101 20.7285 7.05842 24.3189 8.66585 27.8175H16.6163C18.208 24.3137 19.5528 20.7259 20.958
    17.0487C22.3631 13.3716 23.6291 9.75226 24.7822 6.13029H18.3603Z"/>
    <path d="M54.7402 21.4692C53.624 22.0286 52.2424 22.4147 51.2916 22.4147C49.5003 22.4147 48.4944 21.7791 48.1398 19.6096C47.8771 17.9995 47.7694 14.7032 48.0768 11.73H42.1986C41.9097
    15.2758 41.8913 17.4611 42.3536 20.5604C43.0811 25.451 46.0307 28.1326 50.9712 28.0985C52.7677 28.0985 54.3174 27.7255 56.5 26.5804L54.7402 21.4692Z"/>
    <path d="M49.6159 3.69814C48.6677 2.84977 48.1188 1.57591 48.0137 0H42.2984C42.3388 1.13812 42.5331 2.26552 42.8762 3.35144C43.4262 5.11893 44.4381 6.70753 45.8074 7.9531C47.1459 9.14312
    48.7782 9.95379 50.5352 10.3012C51.3225 10.4622 52.1243 10.5423 52.9279 10.5402H54.7507V4.82491H52.9384C51.6042 4.82491 50.4616 4.44144 49.6264 3.69288"/>
    <path d="M38.5661 6.06463C31.6374 6.06463 22.7913 7.59064 25.0606 27.8227H31.2066C30.9702 25.1962 30.7969 23.0424 30.7785 20.9334C30.7785 14.9212 32.3544 11.428 38.4768 11.6775L39.7323
    11.7248V6.13029C39.3199 6.08302 38.6975 6.06726 38.5661 6.06463Z"/>
    <path d="M18.3603 6.13029C17.5015 9.28212 16.4114 12.7911 15.3267 16.0454C14.5387 18.4093 12.8919 22.8743 12.8919 22.8743H12.6293C11.7546 20.584 10.9903 18.4093 10.1945 16.0454C9.08609
    12.7938 7.94618 9.28474 7.09256 6.13029H0.5C1.65304 9.75226 2.9164 13.369 4.3137 17.0487C5.71101 20.7285 7.05842 24.3189 8.66585 27.8175H16.6163C18.208 24.3137 19.5528 20.7259 20.958
    17.0487C22.3631 13.3716 23.6291 9.75226 24.7822 6.13029H18.3603Z"/>
    <path d="M54.7402 21.4692C53.624 22.0286 52.2424 22.4147 51.2916 22.4147C49.5003 22.4147 48.4944 21.7791 48.1398 19.6096C47.8771 17.9995 47.7694 14.7032 48.0768 11.73H42.1986C41.9097
    15.2758 41.8913 17.4611 42.3536 20.5604C43.0811 25.451 46.0307 28.1326 50.9712 28.0985C52.7677 28.0985 54.3174 27.7255 56.5 26.5804L54.7402 21.4692Z"/>
    </svg>`;

    const title = document.createElement('h2');
    title.className = 'sso-overlay-title';
    title.textContent = display.title;

    const text = document.createElement('div');
    text.className = 'sso-overlay-text';
    text.innerHTML = display.text;

    const buttons = createButtons(display);

    wnd.appendChild(logo);
    wnd.appendChild(title);
    wnd.appendChild(text);
    wnd.appendChild(buttons);

    div.appendChild(overlay);

    return overlay;
};

function handleNoAuth(div, event) {
    info(`no auth with ${JSON.stringify(event)}`);
    showOverlay(div, displayList.noAuth);
}

function handleGeoBlocked(div, event) {
    info(`geo blocked with ${JSON.stringify(event)}`);
    showOverlay(div, displayList.geoblocked);
}

function handleNoRoaming(div, event) {
    info(`no roam with ${JSON.stringify(event)}`);
    showOverlay(div, displayList.checkRoaming);
    overlayList.push(div);
}

function handleAgeRestricted(div, event) {
    info(`age restriction with ${JSON.stringify(event)}`);
    showOverlay(div, displayList.ageRestriction);
}

function handlerVideoV2(div, event) {
    const display = (expatDisplayList[event.content.code] || expatDisplayList.DEFAULT)();
    showOverlay(div, display);
}
function handlerAudio(div, event) {
    const display = (AudioplayerDisplayList[event.content.code] || AudioplayerDisplayList.DEFAULT)();
    showOverlay(div, display);
}

const handlers = {
    104: handleGeoBlocked,
    105: handleNoAuth,
    107: handleNoRoaming,
    109: handleAgeRestricted,
    '-1': handlerVideoV2,
    '-2': handlerAudio,
};

function defaultHandler(div, event) {
    info(`in default handler with ${JSON.stringify(event)}`);
    if (event.name === 'canplay') {
        hidePreviousError(div);
    }
}

if (VRT.SSOPlayerEventHandler) {
    error('VRT.SSOPlayerEventHandler has already been taken. Exiting...');
} else {
    VRT.SSOPlayerEventHandler = div => (event) => {
        info(`*** video event ${JSON.stringify(event)}`);
        const handler = handlers[event.code] || defaultHandler;
        handler(div, event);
    };
    window.dispatchEvent(new CustomEvent('ssoPlayerEventHandlerBootstrapped', { bootstrapped: true }));
}
